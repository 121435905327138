export const checkPermission = (feature, privilege) => {
  const data = JSON.parse(localStorage.getDecryptedItem("p_m__"));
  const foundFeature = data?.filter((e) => e?.code === feature);

  let foundPrivilege = foundFeature?.map((e) => {
    return e?.privileges;
  });
  // creating new array with all sub array elements concatenated
  const finalPrivilege = foundPrivilege?.flat(1);

  if (!foundFeature?.length) return false;
  if (foundFeature?.length === 1) {
    return foundFeature[0]?.privileges?.some((e) => e?.code === privilege);
  }
  if (foundFeature?.length > 1) {
    return finalPrivilege?.some((e) => e?.code === privilege);
  }
};
